import React from "react";
import { Container, Card } from "react-bootstrap";

function Acknowledgement(props) {
  const { id } = props;

  return (
    <div>
      <Container className="my-5">
        <Card>
          <Card.Body className="ml-2 text-justify">
            <Card.Title className="mb-5 font-weight-bold">
              <u>
                <h3 className="text-center">Confirmation</h3>
              </u>
            </Card.Title>
            <div className="mb-5">
              <Card.Text>
                Your Privacy Request ID is <strong>{id}</strong>. Please save a
                copy for your records.
              </Card.Text>

              <Card.Text>
                A verification link will be sent to the email address you
                provided for all request types except for an{" "}
                <i>unsubscribe/opt-out of sale</i> request.
              </Card.Text>

              <Card.Text>
                Please check your inbox to verify your request. If you did not
                receive an email from{" "}
                <a
                  href="mailto: no-reply@privacyrequest.corteva.com"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="no-reply@privacyrequest.corteva.com"
                >
                  no-reply@privacyrequest.corteva.com
                </a>
                , please check your spam folder or resubmit your request. Thank
                you.
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Acknowledgement;
