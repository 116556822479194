import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import Acknowledgement from "./Acknowledgement";
import RequestForm from "../shared/components/RequestForm";
import { getService } from "../shared/components/restApi";

function IntakeFormMyself() {
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [configValues, setConfigValues] = useState({});
  const [relationshipType, setRelationshipType] = useState("");

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  const handleRelationshipType = (value) => {
    setRelationshipType(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    let privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER}`;
    if (relationshipType) {
      switch (relationshipType) {
        case "Customer/Farmer/Retailer/Distributor":
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_CUSTOMER}`;
          break;
        case "Vendor":
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_VENDOR}`;
          break;
        case "Employee/Applicant/Former employee/Contractor":
          privacyFormURL = `${process.env.REACT_APP_CORTEVA_SRR_CONFIG_DSAR_INTAKE_MYSELF_EMPLOYEE}`;
          break;
        default:
          break;
      }
    }
    let configOptions = {
      method: "GET",
      url: privacyFormURL,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});

          let finalOrderedActions;

          // changing the order of actions object
          const objectTemp1 = {
            correction: null,
            deletion: null,
            portability: null,
            access: null,
            appeal: null,
          };
          const objectTemp2 = {
            optOut: null,
            correction: null,
            deletion: null,
            portability: null,
            "optOut-data-processing": null,
            access: null,
            appeal: null,
          };

          const finalObjTemp =
            relationshipType === "Employee/Applicant/Former employee/Contractor"
              ? objectTemp1
              : objectTemp2;

          if (Object.keys(actions).length > 0) {
            finalOrderedActions = Object.assign(finalObjTemp, actions);
          }

          const requestTypes = Object.keys(finalOrderedActions).map(
            (request) => {
              switch (request) {
                case "access":
                  return {
                    value: "access",
                    description:
                      "Inform me about the processing of my personal information",
                  };
                case "deletion":
                  return {
                    value: "deletion",
                    description: "Delete my personal information",
                  };
                case "correction":
                  return {
                    value: "correction",
                    description: "Update/correct my personal information",
                  };
                case "portability":
                  return {
                    value: "portability",
                    description: "Copy of my personal information",
                  };
                case "optOut":
                  return {
                    value: "optOut",
                    description: "Unsubscribe me from marketing communications",
                  };
                case "optOut-data-processing":
                  return {
                    value: "optOut-data-processing",
                    description:
                      "Opt-out of sale/sharing for cross-context behavioral advertising/targeted advertising",
                  };
                case "category-access":
                  return {
                    value: "category-access",
                    description:
                      "Limit the use/disclosure of my sensitive personal information",
                  };
                case "appeal":
                    return {
                      value: "appeal",
                      description:
                        "Appeal the determination of my previous privacy request",
                    };

                default:
                  return console.warn("Unknown Request Type", request);
              }
            }
          );

          setConfigValues({
            actions,
            requestTypes,
            settings: settingsId,
            _id: settingsId,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [relationshipType]);

  return (
    <div>
      <SrrHeader heading="Privacy Request" />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <>
            <div className="introText">
              <p>
                If you are an Authorized Agent submitting a privacy request on
                behalf of an individual (Person) or entity other than yourself,
                scroll down to the bottom of the screen to select the Authorized
                Agent form.
              </p>
            </div>

            <RequestForm
              configValues={configValues}
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
              getRelationshipType={handleRelationshipType}
              relationshipType={relationshipType}
              requestFor="Myself"
            />
            <div className="introText">
              <p>
                To access the Authorized Agent form,{" "}
                <a href="/AuthorizedAgent">click here</a>.
              </p>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}

export default IntakeFormMyself;
