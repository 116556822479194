import React from "react";

function SrrHeader(props) {
  return (
    <>
      <div className="heading mb-5">
        <div className="text-center">
          <h1>{props.heading}</h1>
        </div>
      </div>
    </>
  );
}

export default SrrHeader;
