import React from "react";
import { Navbar, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header(props) {

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.Corteva.com/" target="_blank">
          <Image
            src={Logo}
            alt="Corteva logo"
            className="p-2"
            width="220px"
            height="auto"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;
