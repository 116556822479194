import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Recaptcha from "./ReCaptcha";
import { useForm, Controller } from "react-hook-form";
import {
  textValidation,
  emailValidation,
  selectValidation,
  zipcodeValidation,
} from "./Validation";
import getPayload from "./getPayload";
import { getService } from "../../shared/components/restApi";
import { Constants } from "../../shared/components/Constants";
import RequestTypes from "./RequestTypes";

function RequestForm(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [vendorSelected, setVendorSelected] = useState(false);
  const [value, setValue] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [accessRequestType, setAccessRequestType] = useState(false);
  const [updateRequestType, setUpdateRequestType] = useState(false);
  const [appealRequestType, setAppealRequestType] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [appealRequestTypes, setAppealRequestTypes] = useState([]);

  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    requestFor,
    getRelationshipType,
    relationshipType,
  } = props;

  useEffect(() => {
    updateAppealRequestTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appealRequestType]);

  const updateAppealRequestTypes = async () => {
    const formValue = getValues();
    const state = formValue.state ?? formValue.dataSubjectState
    
    const requestTypes = configValues.actions?.appeal?.fields?.find((f) => f.apiIdentifier === "requestTypeThatTheAppealIsBeingSubmittedFor")?.items?.map((item) => {

      const isAnOptionForCalifornia = ['Request for Specific Pieces (Copy) of Personal Information (California only)', 'Know/Access (California only)'].includes(item);

      if (!(state !== 'California' && isAnOptionForCalifornia)) {
        return {
          value: item
        }
      }

      return null;
    })?.filter((type) => type);

    setAppealRequestTypes(requestTypes)
  }

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const handleVendorInput = (e) => {
    getRelationshipType(e.target.value);
    if (e.target.value === "Vendor") {
      setVendorSelected(true);
    } else {
      setVendorSelected(false);
    }
  };

  const { register, handleSubmit, formState, errors, validate, control, getValues } =
    useForm({
      mode: "onChange",
    });

  const onSubmit = (data) => {
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);

      // payload
      const inputsForPayLoad = {
        selectedRequests,
        inputsData: sendRequest,
        configValues,
        recaptchaToken,
      };

      const payload = getPayload(inputsForPayLoad);

      // API request

      const url = process.env.REACT_APP_CURRENT_ENVIRONMENT_API_URL;

      const submitOptions = {
        method: `POST`,
        url,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
            if (response.data.rootId && requestFor === "AuthorizedAgent") {
              const data = new FormData();
              data.append("files", selectedFile);
              data.append("dsarId", response.data.rootId);

              const uploadDocOptions = {
                method: `POST`,
                url: "https://integration.corteva-uat.services.wirewheel.io/upload-file",
                data,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const uploadDocResponse = getService(uploadDocOptions);
              uploadDocResponse
                .then((response) => {
                  if (response.status === 200 && response.data) {
                    console.log("response", response.data);
                  }
                })
                .catch((err) => console.log("File Upload Error", err));
            }
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  const getResponseToken = (token) => {
    setRecaptchaToken(token);
  };

  const handleRequestTypesChange = (requestTypes) => {
    setSelectedRequests(requestTypes);
  };

  const handleAccessRequestType = (bool) => {
    setAccessRequestType(bool);
  };

  const handleUpdateRequestType = (bool) => {
    setUpdateRequestType(bool);
  };

  const handleAppealRequestType = (bool) => {
    setAppealRequestType(bool)
  }

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return (
    <div className="main-content">
      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
            {requestFor === "Myself" ? (
              <>
                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Relationship to Corteva{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Customer/Farmer/Retailer/Distributor"
                            name="relationshipToCorteva"
                            id="customerFarmer"
                            value="Customer/Farmer/Retailer/Distributor"
                            className="radio-label-item"
                            // className="left radio-label-item white-space-nowrap"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      {requestFor === "Myself" ? (
                        <Row className="ml-2">
                          {" "}
                          <Col>
                            <Form.Check
                              type="radio"
                              inline
                              label="Employee/Applicant/Former employee/Contractor"
                              name="relationshipToCorteva"
                              value="Employee/Applicant/Former employee/Contractor"
                              id="employee"
                              className="radio-label-item"
                              onClick={handleVendorInput}
                              ref={register({ required: true })}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Vendor"
                            name="relationshipToCorteva"
                            id="vendor"
                            value="Vendor"
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <RequestTypes
                  requestTypes={configValues.requestTypes}
                  onRequestTypesChange={handleRequestTypesChange}
                  onAccessRequestType={handleAccessRequestType}
                  onUpdateRequestType={handleUpdateRequestType}
                  onAppealRequestType={handleAppealRequestType}
                />

                {updateRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please specify what needs to be updated/corrected{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="updateRequestDetails"
                          className="mb-2"
                          placeholder="Please do not provide any sensitive personal information through this form"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                {accessRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please provide specifics about your request{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="accessRequestDetails"
                          className="mb-2"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                {appealRequestType ? (
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="mb-3">
                        <Form.Label className="fw-bold label-text">
                          Request Type that the Appeal is being submitted for{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        {appealRequestTypes?.map((item) => (
                          <Row>
                            <Col>
                            <Form.Check
                              type="checkbox"
                              label={item.value}
                              name="requestTypeThatTheAppealIsBeingSubmittedFor"
                              value={item.value}
                              id={item.value}
                              ref={register({ required: true })}
                            />
                            </Col>
                          </Row>
                        ))}
                      </Col>
                      <Col className="mb-3">
                        <Form.Label className="fw-bold label-text">
                        Please provide the Request ID of the original request(s) for which the appeal is being submitted <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="pleaseProvideTheRequestIDOfTheOriginalRequestSForWhichTheAppealIsBeingSubmitted"
                          className={!errors.pleaseProvideTheRequestIDOfTheOriginalRequestSForWhichTheAppealIsBeingSubmitted ? "mb-2" : "error-border"}
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                    <Row xs={1}>
                      <Col className="mb-3">
                        <Form.Label className="fw-bold label-text">
                        Details of the Appeal <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="detailsOfTheAppeal"
                          className={!errors.detailsOfTheAppeal ? "mb-2" : "error-border"}
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        className={!errors.firstName ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.firstName && (
                        <small className="text-danger">
                          {" "}
                          {errors.firstName.message}{" "}
                        </small>
                      )}
                    </Col>

                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Last Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        className={!errors.lastName ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.lastName && (
                        <small className="text-danger">
                          {" "}
                          {errors.lastName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Company{" "}
                        {vendorSelected && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        className={!errors.company ? "mb-2" : "error-border"}
                        ref={register({ required: vendorSelected })}
                      />
                      {errors.company && (
                        <small className="text-danger">
                          {" "}
                          {errors.company.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <Form.Label className="fw-bold label-text">
                        Phone Number <span className="text-danger">*</span>
                      </Form.Label>

                      <Controller
                        as={
                          <PhoneInput
                            value={value}
                            country={"us"}
                            onlyCountries={["us"]}
                            onChange={(value) => setValue(value)}
                            inputProps={{
                              name: "phoneNumber",
                              required: true,
                            }}
                            ref={() => {
                              register({ required: true });
                            }}
                            name="phoneNumber"
                            placeholder=""
                            buttonClass="rounded"
                            inputStyle={{ width: "100%" }}
                          />
                        }
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "This field is required",

                          minLength: {
                            value: 10,
                            message: "A valid number is required",
                          },
                          maxLength: {
                            value: 12,
                            message: "A valid number is required",
                          },
                        }}
                      />
                      {errors.phoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.phoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        className={!errors.email ? "mb-2" : "error-border mb-2"}
                        ref={register(emailValidation)}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                      {/* <caption>
                        We'll use this email address to reply to your request
                      </caption> */}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        className={!errors.address ? "mb-2" : "error-border"}
                        ref={register({ required: true })}
                      />
                    </Col>

                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        City <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        className={!errors.city ? "mb-2" : "error-border"}
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        State <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="state"
                        className={!errors.state ? "mb-2" : "error-border"}
                        ref={register(selectValidation)}
                        onChange={updateAppealRequestTypes}
                      >
                        <option value="">Select State</option>
                        {relationshipType ===
                        "Employee/Applicant/Former employee/Contractor"
                          ? Constants.states["employee"].map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))
                          : Constants.states["others"].map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                      </Form.Control>
                      {errors.state && (
                        <small className="text-danger">
                          {" "}
                          {errors.state.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        Postal Zip Code <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="postalZipCode"
                        className={
                          !errors.postalZipCode ? "mb-2" : "error-border"
                        }
                        ref={register(zipcodeValidation)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Authorized Agent Email{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        className={
                          !errors.authorizedAgentEmail ? "mb-2" : "error-border"
                        }
                        ref={register(emailValidation)}
                      />
                      {errors.authorizedAgentEmail && (
                        <small className="text-danger">
                          {" "}
                          {errors.authorizedAgentEmail.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Authorized Agent First Name and Last Name{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="authorizedAgentName"
                        className={
                          !errors.authorizedAgentName ? "mb-2" : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.authorizedAgentName && (
                        <small className="text-danger">
                          {" "}
                          {errors.authorizedAgentName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Authorized Agent Relationship with the Person you
                        represent <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="authorizedAgentRelationshipWithPerson"
                        className={
                          !errors.authorizedAgentRelationshipWithDataSubject
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.authorizedAgentRelationshipWithDataSubject && (
                        <small className="text-danger">
                          {" "}
                          {
                            errors.authorizedAgentRelationshipWithDataSubject
                              .message
                          }{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Relationship to Corteva{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Row className="ml-2">
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Customer/Farmer/Retailer/Distributor"
                            name="dataSubjectRelationship"
                            id="customerFarmer"
                            value="Customer/Farmer/Retailer/Distributor"
                            className="radio-label-item"
                            // className="left radio-label-item white-space-nowrap"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                      {requestFor !== "CDPA" ? (
                        <Row className="ml-2">
                          {" "}
                          <Col>
                            <Form.Check
                              type="radio"
                              inline
                              label="Employee/Applicant/Former employee/Contractor"
                              name="dataSubjectRelationship"
                              value="Employee/Applicant/Former employee/Contractor"
                              id="employee"
                              className="radio-label-item"
                              onClick={handleVendorInput}
                              ref={register({ required: true })}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      <Row className="ml-2">
                        {" "}
                        <Col>
                          <Form.Check
                            type="radio"
                            inline
                            label="Vendor"
                            name="dataSubjectRelationship"
                            id="vendor"
                            value="Vendor"
                            className="radio-label-item"
                            onClick={handleVendorInput}
                            ref={register({ required: true })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <RequestTypes
                  requestTypes={configValues.requestTypes}
                  onRequestTypesChange={handleRequestTypesChange}
                  onAccessRequestType={handleAccessRequestType}
                  onUpdateRequestType={handleUpdateRequestType}
                  onAppealRequestType={handleAppealRequestType}
                />

                {updateRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please specify what needs to be updated/corrected{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="updateRequestDetails"
                          className="mb-2"
                          placeholder="Please do not provide any sensitive personal information through this form"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                {accessRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please provide specifics about your request{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="accessRequestDetails"
                          className="mb-2"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                {appealRequestType ? (
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="mb-3">
                        <Form.Label className="fw-bold label-text">
                          Request Type that the Appeal is being submitted for{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        {appealRequestTypes?.map((item) => (
                          <Row>
                            <Col>
                            <Form.Check
                              type="checkbox"
                              label={item.value}
                              name="requestTypeThatTheAppealIsBeingSubmittedFor"
                              value={item.value}
                              id={item.value}
                              ref={register({ required: true })}
                            />
                            </Col>
                          </Row>
                        ))}
                      </Col>
                      <Col className="mb-3">
                        <Form.Label className="fw-bold label-text">
                        Please provide the Request ID of the original request(s) for which the appeal is being submitted <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="pleaseProvideTheRequestIDOfTheOriginalRequestSForWhichTheAppealIsBeingSubmitted"
                          className={!errors.pleaseProvideTheRequestIDOfTheOriginalRequestSForWhichTheAppealIsBeingSubmitted ? "mb-2" : "error-border"}
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                    <Row xs={1}>
                      <Col className="mb-3">
                        <Form.Label className="fw-bold label-text">
                        Details of the Appeal <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="detailsOfTheAppeal"
                          className={!errors.detailsOfTheAppeal ? "mb-2" : "error-border"}
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                <Form.Group className="mb-3">
                  <Row xs={1} md={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person First Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectFirstName"
                        className={
                          !errors.dataSubjectFirstName ? "mb-2" : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.dataSubjectFirstName && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectFirstName.message}{" "}
                        </small>
                      )}
                    </Col>

                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Last Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectLastName"
                        className={
                          !errors.dataSubjectLastName ? "mb-2" : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.dataSubjectLastName && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectLastName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Company{" "}
                        {vendorSelected && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectCompany"
                        className={!errors.company ? "mb-2" : "error-border"}
                        ref={register({ required: vendorSelected })}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Phone Number{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Controller
                        as={
                          <PhoneInput
                            value={value}
                            country={"us"}
                            onlyCountries={["us"]}
                            onChange={(value) => setValue(value)}
                            inputProps={{
                              name: "dataSubjectPhoneNumber",
                              required: true,
                            }}
                            ref={() => {
                              register({ required: true });
                            }}
                            name="dataSubjectPhoneNumber"
                            buttonClass="rounded"
                            inputStyle={{ width: "100%" }}
                          />
                        }
                        name="dataSubjectPhoneNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "This field is required",

                          minLength: {
                            value: 10,
                            message: "A valid number is required",
                          },
                          maxLength: {
                            value: 12,
                            message: "A valid number is required",
                          },
                        }}
                      />

                      {errors.dataSubjectPhoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectPhoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="dataSubjectEmail"
                        className={
                          !errors.dataSubjectEmail ? "mb-2" : "error-border"
                        }
                        ref={register(emailValidation)}
                      />
                      {errors.dataSubjectEmail && (
                        <small className="text-danger">
                          {" "}
                          {errors.dataSubjectEmail.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectAddress"
                        className={
                          !errors.dataSubjectAddress ? "mb-2" : "error-border"
                        }
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person City <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectCity"
                        className={
                          !errors.dataSubjectCity ? "mb-2" : "error-border"
                        }
                        ref={register({ required: true })}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person State <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="dataSubjectState"
                        className={!errors.state ? "mb-2" : "error-border"}
                        ref={register(selectValidation)}
                        onChange={updateAppealRequestTypes}
                      >
                        <option value="">Select State</option>
                        {Constants.states["others"].map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                      {errors.state && (
                        <small className="text-danger">
                          {" "}
                          {errors.state.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Person Postal Zip Code{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dataSubjectPostalZipCde"
                        className={
                          !errors.dataSubjectPostalZipCde
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register(zipcodeValidation)}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group as={Col} controlId="file" className="mt-4">
                  <Form.Row>
                    <Form.Label className="fw-bold label-text">
                      Upload completed Authorized Agent form here.{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.File
                      id="uploadFile"
                      name="uploadFile"
                      onChange={handleFileChange}
                      ref={register({ required: true })}
                      className=""
                      required
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf, image/*"
                    />
                  </Form.Row>
                </Form.Group>
              </>
            )}

            <div className="d-flex justify-content-center mt-4">
              <Recaptcha
                reCaptchaClick={handleReCaptchaClick}
                responseToken={getResponseToken}
              />
            </div>

            <Form.Group>
              <Row className="d-flex justify-content-center mt-5">
                <Col sm={12} md={10} lg={10} className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    style={{
                      width: "inherit",
                    }}
                    disabled={
                      !formState.isValid ||
                      disableSubmit ||
                      validate ||
                      !selectedRequests.length
                    }
                  >
                    Submit Request
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RequestForm;
