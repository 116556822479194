import axios from "axios";
//const serviceUrl = 'Production url';
export async function getService(getOptions) {
  try {
    let promise = await axios(getOptions);
    return promise;
  } catch (e) {
    let errorRespose;
    if (typeof e.response === "undefined") {
      errorRespose = "Internal Error";
    } else {
      errorRespose = e;
    }
    return errorRespose;
  }
}
